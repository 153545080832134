import React from "react"
import Swiper from "react-id-swiper"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import "swiper/css/swiper.css"

class SwiperSample extends React.Component {
  render() {
    const {
      children,
      slidesperview,
      space,
      // responsive,
      loop,
      centered,
    } = this.props
    // console.log(space)
    const params = {
      modules: [Pagination, Navigation],
      slidesPerView: slidesperview,
      spaceBetween: space ? space : 30,
      containerClass: "swiper-container pb-5",
      loop: loop ? loop : false,
      nested: true,
      centered: centered ? centered : false,
      autoplay: {
        delay: 20000,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        // 768: {
        //   slidesPerView: 3,
        //   spaceBetween: 0,
        // },
        // 640: {
        //   slidesPerView: 3,
        //   spaceBetween: 0,
        // },
        // 320: {
        //   slidesPerView: 1,
        //   spaceBetween: 0,
        // },
        200: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        // 640: {
        //   slidesPerView: 2,
        //   spaceBetween: 10,
        // },
        768: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1000: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
        1366: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
      },
    }
    return <Swiper {...params}>{children}</Swiper>
  }
}

export default SwiperSample
